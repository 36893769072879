import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import 'vant/lib/index.css';
import 'amfe-flexible'
import Header from './components/Header.vue'
import TabBar from './components/TabBar.vue'

import { Button, NavBar, Swipe, SwipeItem, Popup, Stepper, Image as VanImage, CountDown, Search } from 'vant'
import i18n from './i18n'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.component('Header', Header)
app.component('TabBar', TabBar)
app.use(Button).use(NavBar).use(Swipe).use(SwipeItem).use(Popup).use(Stepper).use(VanImage).use(CountDown)
  .use(Search)
app.mount('#app')
