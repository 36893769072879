import { createI18n } from "vue-i18n";

import en from './lang/en'
import zh from './lang/zh'

const i18n = createI18n({
  locale: sessionStorage.getItem('lang') || 'en',
  messages: {
    en,
    zh
  }
})

export default i18n;
