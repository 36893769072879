import { get, post } from './http'

// 等级提升
export const getOptionDesc = p => get('/index/index/getOptionDesc', p);

// 轮播图
export const getBanner = p => get('/index/index/getBanner', p);

// 公告列表
export const getNoticeList = p => get('/index/index/getNoticeList', p);

// 公告详情
export const getNoticeDetail = p => get('/index/index/getNoticeDetail', p);

// 获取配置
export const getConfig = p => get('/index/index/getConfig', p);

// 矿机信息
export const kuangList = p => get('/index/user/kuangList', p);

// 矿机详情
export const kuangDetail = p => get('/index/user/kuangDetail', p);

// 购买矿机
export const createOrder = p => post('/index/user/createOrder', p);

// 矿机订单列表
export const getOrderList = p => get('/index/user/getOrderList', p);

// 矿机订单收益详情
export const getProfitList = p => get('/index/user/getProfitList', p)

// 国家区号
export const countryList = p => get('/index/index/countryList', p)
