<template>
  <div class="tabBar">
    <div :class="{active: activeIndex === 0}" class="item" @click="router.push('/')">
      <img class="icon" src="@/assets/img/icon_home.png">
      <img class="icon" src="@/assets/img/icon_home_active.png">
      <div class="text">{{ $t('e9') }}</div>
      <div class="circle"></div>
    </div>
    <div :class="{active: activeIndex === 1}" class="item" @click="router.push('/mining')">
      <img class="icon" src="@/assets/img/icon_mining.png">
      <img class="icon" src="@/assets/img/icon_mining_active.png">
      <div class="text">{{ $t('f0') }}</div>
      <div class="circle"></div>
    </div>
    <div :class="{active: activeIndex === 2}" class="item" @click="router.push('/wallet')">
      <img class="icon" src="@/assets/img/icon_wallet.png">
      <img class="icon" src="@/assets/img/icon_wallet_active.png">
      <div class="text">{{ $t('f1') }}</div>
      <div class="circle"></div>
    </div>
    <div :class="{active: activeIndex === 3}" class="item" @click="router.push('/mine')">
      <img class="icon" src="@/assets/img/icon_mine.png">
      <img class="icon" src="@/assets/img/icon_mine_active.png">
      <div class="text">{{ $t('f2') }}</div>
      <div class="circle"></div>
    </div>
  </div>
  <div class="block"></div>
</template>

<script setup>
  import { useRouter } from "vue-router";

  const router = useRouter()
  defineProps({
    activeIndex: {
      type: Number,
      default: 0
    }
  })
</script>

<style scoped lang="less">
.tabBar{
  height: 68px;
  background: #FFFFFF;
  border-radius: 16px 16px 0 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 99;

  .item{
    position: relative;
    text-align: center;

    .icon{
      width: 24px;
      margin: 0 auto 8px auto;
      display: none;

    }
    .icon:first-child{
      display: block;
    }
    .text{
      color: #B0B0B0;
      font-size: 12px;
      text-align: center;
    }
    .circle{
      position: absolute;
      width: 32px;
      height: 32px;
      background: #7441C0;
      border-radius: 50%;
      left: 50%;
      transform: translateX(-50%);
      top: -4px;
      z-index: -1;
      box-shadow: 0 5px 18px 0 rgba(116,65,192,0.702);
      display: none;
    }
  }
  .active{
    .icon{
      display: block;
    }
    .icon:first-child{
      display: none;
    }
    .text{
      color: #7441C0;
    }
    .circle{
      display: block;
    }
  }
}
.block{
  height: 68px;
}
</style>
